import 'owl.carousel';
import './jquery.selectric.min.js';

$(document).ready(function(){
    $('select:not('.styled')').selectric({
        maxHeight: 200
    });

    var $window = $(window);

    var $topslider = $('.top-slider');

    $topslider.owlCarousel({
        loop: true,
        margin: 0,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        nav:false,
        dots: false,
        items:1
    });

    $topslider.on('changed.owl.carousel', function(event) {
        var page = event.item.index;

        if(page > 4) page = 2;
        if(page === 1) page = 4;

        $('.js-top-slider-count').html(page - 1);

        $('.top-slider__nav__line').css({ left: (page - 2) * 60 });
      });

    $('.js-top-slider-dot').on('click', function() {
     var index = $(this).index();

     $topslider.trigger('to.owl.carousel', [index, 300]);
    });

    var $consultationslider = $('.consultation-content__slider');
    var $consultationHowWorkSlider = $('.consultation-how-work__list');
    
    $consultationslider.owlCarousel({
        loop: true,
        margin: 0,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        nav:false,
        dots: false,
        items:1
    });

    $('.consultation-nav-slider__btn--next').click(function() {
        if($window.width() <= 767) {
            $consultationHowWorkSlider.trigger('next.owl.carousel');
        } else {
            $consultationslider.trigger('next.owl.carousel');
        }
    });

    $('.consultation-nav-slider__btn--prev').click(function() {
        if($window.width() <= 767) {
            $consultationHowWorkSlider.trigger('prev.owl.carousel');
        } else {
            $consultationslider.trigger('prev.owl.carousel');
        }
    });

    var $reviewsslider = $('.reviews__slider');
    
    $reviewsslider.owlCarousel({
        loop: true,
        margin: 5,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        nav: false,
        dots: false,
        items:1
    });

    $('.reviews-nav-slider__btn--next').click(function() {
        $reviewsslider.trigger('next.owl.carousel');
    });

    $('.reviews-nav-slider__btn--prev').click(function() {
        $reviewsslider.trigger('prev.owl.carousel');
    });

    var $uniquenessslider = $('.uniqueness-slider');
    setSliders();

    window.onresize = function(event) {
        setSliders();
    };
    

    $('.questions-list__item__title').on('click', function() {
        $(this).parent().toggleClass('open');
        return false;
    });

    $window.scroll(function() {
        statisticsScreenAnimation();
        statisticsScreenConsultation();
    });

    function statisticsScreenAnimation() {
        var parent = $('.statistics');

        var scrollTop = $window.scrollTop();
        var windowWidth = $window.width();
        var windowHeight = $window.height();

        var statisticsOffsetTop = parent.offset().top - (windowHeight / 2); 

        if(windowWidth >= 1199 && scrollTop >= statisticsOffsetTop) {
            if(!$('.statistics__screen').hasClass('animate')) {
                $('.statistics__screen').addClass('animate');
                $('.statistics-li-last').addClass('animate');

                setTimeout(function() {
                    $('.statistics__screen').hide();
                }, 5400);
            }
        }
    }

    function statisticsScreenConsultation() {
        var parent = $('.consultation-how-work__screen');

        var scrollTop = $window.scrollTop();
        var windowWidth = $window.width();
        var windowHeight = $window.height();

        var statisticsOffsetTop = parent.offset().top - (windowHeight / 2); 

        if(windowWidth >= 1199 && scrollTop >= statisticsOffsetTop) {
            if(!parent.hasClass('animate')) {
                parent.addClass('animate');
            }
        }
    }

    function setSliders() {
        if($window.width() <= 767) {
            $uniquenessslider
                .addClass('owl-carousel')
                .owlCarousel({
                    loop: true,
                    margin: 10,
                    autoplay:true,
                    autoplayTimeout:5000,
                    autoplayHoverPause:true,
                    nav: false,
                    dots: false,
                    items:1
                });

            $('.uniqueness-nav-slider__btn--next').click(function() {
                $uniquenessslider.trigger('next.owl.carousel');
            });
        
            $('.uniqueness-nav-slider__btn--prev').click(function() {
                $uniquenessslider.trigger('prev.owl.carousel');
            });
    
            $consultationHowWorkSlider
                .addClass('owl-carousel')
                .owlCarousel({
                    loop: true,
                    margin: 10,
                    autoplay:true,
                    autoplayTimeout:5000,
                    autoplayHoverPause:true,
                    nav: false,
                    dots: false,
                    items:1
                });
        } else {
            $uniquenessslider.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
            $uniquenessslider.find('.owl-stage-outer').children().unwrap();

            $consultationHowWorkSlider.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
            $consultationHowWorkSlider.find('.owl-stage-outer').children().unwrap();
        }
    }
});